// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../styleguide/components/Link/A.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as ID from "../../../libs/ID.res.js";
import * as Api from "../../../api/Api.res.js";
import * as Role from "../../../models/Role.res.js";
import * as Hooks from "../../../libs/Hooks.res.js";
import * as Money from "../../../libs/Money.res.js";
import * as React from "react";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as $$String from "rescript/lib/es6/string.js";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Control from "../../../styleguide/components/Control/Control.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Dropdown from "../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as IconInfo from "../../../styleguide/icons/IconInfo.res.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pagination from "../../../styleguide/components/Pagination/Pagination.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconSorting from "../../../styleguide/icons/IconSorting.res.js";
import * as OrderStatus from "../../../models/OrderStatus.res.js";
import * as SearchField from "../../../styleguide/forms/SearchField/SearchField.res.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as AdminOrderData from "../../../models/AdminOrderData.res.js";
import * as AdminTopNavbar from "../../dashboard/common/admin-top-navbar/AdminTopNavbar.res.js";
import Format from "date-fns/format";
import * as JsxRuntime from "react/jsx-runtime";
import * as AdminOrdersScss from "./AdminOrders.scss";
import * as AdminOrderProvider from "../../../models/AdminOrderProvider.res.js";
import * as AdminOrdersActionBar from "./AdminOrdersActionBar.res.js";
import * as AdminOrdersNotificationBar from "./AdminOrdersNotificationBar.res.js";

var css = AdminOrdersScss;

var cmp = Caml_obj.compare;

var OrderStatusId = Belt_Id.MakeComparable({
      cmp: cmp
    });

function AdminOrders$AdminOrders(props) {
  var isNotOnlyProviderMember = props.isNotOnlyProviderMember;
  var isConcierge = props.isConcierge;
  var orders = props.orders;
  var totalReservations = props.totalReservations;
  var totalPages = props.totalPages;
  var perPage = props.perPage;
  var offset = props.offset;
  var currentPage = props.currentPage;
  var timeoutId = React.useRef(undefined);
  React.useEffect((function () {
          return (function () {
                    Belt_Option.getWithDefault(Belt_Option.map(timeoutId.current, (function (prim) {
                                clearTimeout(prim);
                              })), undefined);
                  });
        }), []);
  var initialState = React.useMemo((function () {
          return {
                  sortBy: {
                    NAME: "ID",
                    VAL: "Desc"
                  },
                  sortDirection: "Desc",
                  orders: orders,
                  totalPages: totalPages,
                  totalReservations: totalReservations,
                  offset: offset,
                  perPage: perPage,
                  currentPage: currentPage,
                  fetching: false,
                  search: "",
                  statusFilter: Belt_Set.fromArray([
                        "InProgress",
                        "Finalized",
                        "Cancelled"
                      ], OrderStatusId),
                  providerFilter: ID.$$Set.make(),
                  selectedOrders: ID.$$Set.make(),
                  serverError: undefined,
                  singleOrderId: ID.$$Set.make(),
                  showNotificationMessage: false,
                  timeoutId: {
                    contents: undefined
                  }
                };
        }), []);
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "FetchOrders" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            sortBy: state.sortBy,
                            sortDirection: state.sortDirection,
                            orders: state.orders,
                            totalPages: state.totalPages,
                            totalReservations: state.totalReservations,
                            offset: state.offset,
                            perPage: state.perPage,
                            currentPage: state.currentPage,
                            fetching: true,
                            search: state.search,
                            statusFilter: state.statusFilter,
                            providerFilter: state.providerFilter,
                            selectedOrders: state.selectedOrders,
                            serverError: state.serverError,
                            singleOrderId: state.singleOrderId,
                            showNotificationMessage: state.showNotificationMessage,
                            timeoutId: state.timeoutId
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              var state = param.state;
                              var match = state.search;
                              var tmp = match === "" ? undefined : state.search;
                              $$Promise.wait(Api.fetchAdminOrders(state.currentPage, tmp, state.sortBy, Belt_Set.toList(state.statusFilter), Belt_Set.toList(state.providerFilter)), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "UpdateOrders",
                                                    _0: x._0
                                                  });
                                      } else {
                                        return SentryLogger.error1({
                                                    rootModule: "AdminOrders",
                                                    subModulePath: {
                                                      hd: "AdminOrders",
                                                      tl: /* [] */0
                                                    },
                                                    value: "make",
                                                    fullPath: "AdminOrders.AdminOrders.make"
                                                  }, "AdminOrders::FetchAdminOrders::Error", [
                                                    "Error",
                                                    x._0
                                                  ]);
                                      }
                                    }));
                            })
                        };
              case "DeselectAll" :
                  return {
                          TAG: "Update",
                          _0: {
                            sortBy: state.sortBy,
                            sortDirection: state.sortDirection,
                            orders: state.orders,
                            totalPages: state.totalPages,
                            totalReservations: state.totalReservations,
                            offset: state.offset,
                            perPage: state.perPage,
                            currentPage: state.currentPage,
                            fetching: state.fetching,
                            search: state.search,
                            statusFilter: state.statusFilter,
                            providerFilter: state.providerFilter,
                            selectedOrders: ID.$$Set.make(),
                            serverError: state.serverError,
                            singleOrderId: state.singleOrderId,
                            showNotificationMessage: state.showNotificationMessage,
                            timeoutId: state.timeoutId
                          }
                        };
              case "ToggleSelectionOfAllItems" :
                  return {
                          TAG: "Update",
                          _0: {
                            sortBy: state.sortBy,
                            sortDirection: state.sortDirection,
                            orders: state.orders,
                            totalPages: state.totalPages,
                            totalReservations: state.totalReservations,
                            offset: state.offset,
                            perPage: state.perPage,
                            currentPage: state.currentPage,
                            fetching: state.fetching,
                            search: state.search,
                            statusFilter: state.statusFilter,
                            providerFilter: state.providerFilter,
                            selectedOrders: orders.length !== Belt_Set.size(state.selectedOrders) ? Belt_Set.fromArray(Belt_Array.map(orders, (function (order) {
                                          return order.id;
                                        })), ID.Comparable) : ID.$$Set.make(),
                            serverError: state.serverError,
                            singleOrderId: state.singleOrderId,
                            showNotificationMessage: state.showNotificationMessage,
                            timeoutId: state.timeoutId
                          }
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "Paginate" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            sortBy: state.sortBy,
                            sortDirection: state.sortDirection,
                            orders: state.orders,
                            totalPages: state.totalPages,
                            totalReservations: state.totalReservations,
                            offset: state.offset,
                            perPage: state.perPage,
                            currentPage: action._0,
                            fetching: state.fetching,
                            search: state.search,
                            statusFilter: state.statusFilter,
                            providerFilter: state.providerFilter,
                            selectedOrders: state.selectedOrders,
                            serverError: state.serverError,
                            singleOrderId: state.singleOrderId,
                            showNotificationMessage: state.showNotificationMessage,
                            timeoutId: state.timeoutId
                          },
                          _1: (function (param) {
                              param.dispatch("FetchOrders");
                            })
                        };
              case "SortBy" :
                  var column = action._0;
                  var match = state.sortDirection;
                  var sortDirection = match === "Desc" ? "Asc" : "Desc";
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            sortBy: column === "Provider" ? ({
                                  NAME: "Provider",
                                  VAL: sortDirection
                                }) : (
                                column === "ID" ? ({
                                      NAME: "ID",
                                      VAL: sortDirection
                                    }) : (
                                    column === "User" ? ({
                                          NAME: "User",
                                          VAL: sortDirection
                                        }) : (
                                        column === "Status" ? ({
                                              NAME: "Status",
                                              VAL: sortDirection
                                            }) : (
                                            column === "Product" ? ({
                                                  NAME: "Product",
                                                  VAL: sortDirection
                                                }) : ({
                                                  NAME: "Company",
                                                  VAL: sortDirection
                                                })
                                          )
                                      )
                                  )
                              ),
                            sortDirection: sortDirection,
                            orders: state.orders,
                            totalPages: state.totalPages,
                            totalReservations: state.totalReservations,
                            offset: state.offset,
                            perPage: state.perPage,
                            currentPage: state.currentPage,
                            fetching: state.fetching,
                            search: state.search,
                            statusFilter: state.statusFilter,
                            providerFilter: state.providerFilter,
                            selectedOrders: state.selectedOrders,
                            serverError: state.serverError,
                            singleOrderId: state.singleOrderId,
                            showNotificationMessage: state.showNotificationMessage,
                            timeoutId: state.timeoutId
                          },
                          _1: (function (param) {
                              param.dispatch("FetchOrders");
                            })
                        };
              case "UpdateOrders" :
                  var res = action._0;
                  return {
                          TAG: "Update",
                          _0: {
                            sortBy: state.sortBy,
                            sortDirection: state.sortDirection,
                            orders: res.orders,
                            totalPages: res.totalPages,
                            totalReservations: res.totalReservations,
                            offset: res.offset,
                            perPage: state.perPage,
                            currentPage: res.currentPage,
                            fetching: false,
                            search: state.search,
                            statusFilter: state.statusFilter,
                            providerFilter: state.providerFilter,
                            selectedOrders: state.selectedOrders,
                            serverError: state.serverError,
                            singleOrderId: ID.$$Set.make(),
                            showNotificationMessage: state.showNotificationMessage,
                            timeoutId: state.timeoutId
                          }
                        };
              case "UpdateSearchInput" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            sortBy: state.sortBy,
                            sortDirection: state.sortDirection,
                            orders: state.orders,
                            totalPages: state.totalPages,
                            totalReservations: state.totalReservations,
                            offset: state.offset,
                            perPage: state.perPage,
                            currentPage: 1,
                            fetching: state.fetching,
                            search: action._0,
                            statusFilter: state.statusFilter,
                            providerFilter: state.providerFilter,
                            selectedOrders: state.selectedOrders,
                            serverError: state.serverError,
                            singleOrderId: state.singleOrderId,
                            showNotificationMessage: state.showNotificationMessage,
                            timeoutId: state.timeoutId
                          },
                          _1: (function (param) {
                              param.dispatch("FetchOrders");
                            })
                        };
              case "ToggleStatusFilter" :
                  var status = action._0;
                  var statusFilter = Belt_Set.has(state.statusFilter, status) ? Belt_Set.remove(state.statusFilter, status) : Belt_Set.add(state.statusFilter, status);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            sortBy: state.sortBy,
                            sortDirection: state.sortDirection,
                            orders: state.orders,
                            totalPages: state.totalPages,
                            totalReservations: state.totalReservations,
                            offset: state.offset,
                            perPage: state.perPage,
                            currentPage: 1,
                            fetching: state.fetching,
                            search: state.search,
                            statusFilter: statusFilter,
                            providerFilter: state.providerFilter,
                            selectedOrders: state.selectedOrders,
                            serverError: state.serverError,
                            singleOrderId: state.singleOrderId,
                            showNotificationMessage: state.showNotificationMessage,
                            timeoutId: state.timeoutId
                          },
                          _1: (function (param) {
                              param.dispatch("FetchOrders");
                            })
                        };
              case "ToggleProviderFilter" :
                  var providerId = action._0;
                  var providerFilter = Belt_Set.has(state.providerFilter, providerId) ? Belt_Set.remove(state.providerFilter, providerId) : Belt_Set.add(state.providerFilter, providerId);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            sortBy: state.sortBy,
                            sortDirection: state.sortDirection,
                            orders: state.orders,
                            totalPages: state.totalPages,
                            totalReservations: state.totalReservations,
                            offset: state.offset,
                            perPage: state.perPage,
                            currentPage: 1,
                            fetching: state.fetching,
                            search: state.search,
                            statusFilter: state.statusFilter,
                            providerFilter: providerFilter,
                            selectedOrders: state.selectedOrders,
                            serverError: state.serverError,
                            singleOrderId: state.singleOrderId,
                            showNotificationMessage: state.showNotificationMessage,
                            timeoutId: state.timeoutId
                          },
                          _1: (function (param) {
                              param.dispatch("FetchOrders");
                            })
                        };
              case "PerformBulkAction" :
                  var actionType = action._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            sortBy: state.sortBy,
                            sortDirection: state.sortDirection,
                            orders: state.orders,
                            totalPages: state.totalPages,
                            totalReservations: state.totalReservations,
                            offset: state.offset,
                            perPage: state.perPage,
                            currentPage: state.currentPage,
                            fetching: true,
                            search: state.search,
                            statusFilter: state.statusFilter,
                            providerFilter: state.providerFilter,
                            selectedOrders: state.selectedOrders,
                            serverError: state.serverError,
                            singleOrderId: state.singleOrderId,
                            showNotificationMessage: state.showNotificationMessage,
                            timeoutId: state.timeoutId
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              var state = param.state;
                              $$Promise.wait(Api.updateOrderStatus(Belt_Set.toArray(state.selectedOrders), OrderStatus.toString(actionType)), (function (x) {
                                      if (x.TAG === "Ok") {
                                        dispatch("FetchOrders");
                                        if (!Belt_Set.has(state.statusFilter, actionType)) {
                                          return dispatch({
                                                      TAG: "ShowStatusNotification",
                                                      _0: true
                                                    });
                                        } else {
                                          return ;
                                        }
                                      }
                                      dispatch({
                                            TAG: "ServerError",
                                            _0: "Something went wrong, please try again!"
                                          });
                                      dispatch({
                                            TAG: "FetchingStatusChange",
                                            _0: false
                                          });
                                    }));
                            })
                        };
              case "SingleOrderStatusUpdate" :
                  var actionType$1 = action._1;
                  var orderId = action._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            sortBy: state.sortBy,
                            sortDirection: state.sortDirection,
                            orders: state.orders,
                            totalPages: state.totalPages,
                            totalReservations: state.totalReservations,
                            offset: state.offset,
                            perPage: state.perPage,
                            currentPage: state.currentPage,
                            fetching: true,
                            search: state.search,
                            statusFilter: state.statusFilter,
                            providerFilter: state.providerFilter,
                            selectedOrders: state.selectedOrders,
                            serverError: state.serverError,
                            singleOrderId: Belt_Set.add(state.singleOrderId, orderId),
                            showNotificationMessage: state.showNotificationMessage,
                            timeoutId: state.timeoutId
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              $$Promise.wait(Api.updateOrderStatus([orderId], OrderStatus.toString(actionType$1)), (function (x) {
                                      if (x.TAG === "Ok") {
                                        dispatch("FetchOrders");
                                        if (!Belt_Set.has(state.statusFilter, actionType$1)) {
                                          return dispatch({
                                                      TAG: "ShowStatusNotification",
                                                      _0: true
                                                    });
                                        } else {
                                          return ;
                                        }
                                      }
                                      dispatch({
                                            TAG: "ServerError",
                                            _0: "Something went wrong, please try again!"
                                          });
                                      dispatch({
                                            TAG: "FetchingStatusChange",
                                            _0: false
                                          });
                                    }));
                            })
                        };
              case "ToggleSelectionOfItem" :
                  var id = action._0;
                  return {
                          TAG: "Update",
                          _0: {
                            sortBy: state.sortBy,
                            sortDirection: state.sortDirection,
                            orders: state.orders,
                            totalPages: state.totalPages,
                            totalReservations: state.totalReservations,
                            offset: state.offset,
                            perPage: state.perPage,
                            currentPage: state.currentPage,
                            fetching: state.fetching,
                            search: state.search,
                            statusFilter: state.statusFilter,
                            providerFilter: state.providerFilter,
                            selectedOrders: Belt_Set.has(state.selectedOrders, id) ? Belt_Set.remove(state.selectedOrders, id) : Belt_Set.add(state.selectedOrders, id),
                            serverError: state.serverError,
                            singleOrderId: state.singleOrderId,
                            showNotificationMessage: state.showNotificationMessage,
                            timeoutId: state.timeoutId
                          }
                        };
              case "FetchingStatusChange" :
                  return {
                          TAG: "Update",
                          _0: {
                            sortBy: state.sortBy,
                            sortDirection: state.sortDirection,
                            orders: state.orders,
                            totalPages: state.totalPages,
                            totalReservations: state.totalReservations,
                            offset: state.offset,
                            perPage: state.perPage,
                            currentPage: state.currentPage,
                            fetching: action._0,
                            search: state.search,
                            statusFilter: state.statusFilter,
                            providerFilter: state.providerFilter,
                            selectedOrders: state.selectedOrders,
                            serverError: state.serverError,
                            singleOrderId: ID.$$Set.make(),
                            showNotificationMessage: state.showNotificationMessage,
                            timeoutId: state.timeoutId
                          }
                        };
              case "ServerError" :
                  var serverError = action._0;
                  if (serverError !== undefined) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              sortBy: state.sortBy,
                              sortDirection: state.sortDirection,
                              orders: state.orders,
                              totalPages: state.totalPages,
                              totalReservations: state.totalReservations,
                              offset: state.offset,
                              perPage: state.perPage,
                              currentPage: state.currentPage,
                              fetching: state.fetching,
                              search: state.search,
                              statusFilter: state.statusFilter,
                              providerFilter: state.providerFilter,
                              selectedOrders: state.selectedOrders,
                              serverError: serverError,
                              singleOrderId: state.singleOrderId,
                              showNotificationMessage: state.showNotificationMessage,
                              timeoutId: state.timeoutId
                            },
                            _1: (function (param) {
                                var dispatch = param.dispatch;
                                setTimeout((function () {
                                        dispatch({
                                              TAG: "ServerError",
                                              _0: undefined
                                            });
                                      }), 5000);
                              })
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              sortBy: state.sortBy,
                              sortDirection: state.sortDirection,
                              orders: state.orders,
                              totalPages: state.totalPages,
                              totalReservations: state.totalReservations,
                              offset: state.offset,
                              perPage: state.perPage,
                              currentPage: state.currentPage,
                              fetching: state.fetching,
                              search: state.search,
                              statusFilter: state.statusFilter,
                              providerFilter: state.providerFilter,
                              selectedOrders: state.selectedOrders,
                              serverError: undefined,
                              singleOrderId: state.singleOrderId,
                              showNotificationMessage: state.showNotificationMessage,
                              timeoutId: state.timeoutId
                            }
                          };
                  }
              case "ShowStatusNotification" :
                  if (action._0) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              sortBy: state.sortBy,
                              sortDirection: state.sortDirection,
                              orders: state.orders,
                              totalPages: state.totalPages,
                              totalReservations: state.totalReservations,
                              offset: state.offset,
                              perPage: state.perPage,
                              currentPage: state.currentPage,
                              fetching: state.fetching,
                              search: state.search,
                              statusFilter: state.statusFilter,
                              providerFilter: state.providerFilter,
                              selectedOrders: state.selectedOrders,
                              serverError: state.serverError,
                              singleOrderId: state.singleOrderId,
                              showNotificationMessage: true,
                              timeoutId: state.timeoutId
                            },
                            _1: (function (param) {
                                var dispatch = param.dispatch;
                                var state = param.state;
                                var x = state.timeoutId.contents;
                                if (x !== undefined) {
                                  clearTimeout(Caml_option.valFromOption(x));
                                }
                                state.timeoutId.contents = Caml_option.some(setTimeout((function () {
                                            dispatch({
                                                  TAG: "ShowStatusNotification",
                                                  _0: false
                                                });
                                          }), 5000));
                              })
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              sortBy: state.sortBy,
                              sortDirection: state.sortDirection,
                              orders: state.orders,
                              totalPages: state.totalPages,
                              totalReservations: state.totalReservations,
                              offset: state.offset,
                              perPage: state.perPage,
                              currentPage: state.currentPage,
                              fetching: state.fetching,
                              search: state.search,
                              statusFilter: state.statusFilter,
                              providerFilter: state.providerFilter,
                              selectedOrders: state.selectedOrders,
                              serverError: state.serverError,
                              singleOrderId: state.singleOrderId,
                              showNotificationMessage: false,
                              timeoutId: state.timeoutId
                            }
                          };
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var tmp;
  switch (props.userRole) {
    case "Admin" :
        tmp = JsxRuntime.jsx(AdminTopNavbar.make, {
              selectedCategory: "Orders",
              isAdmin: true,
              isConcierge: isConcierge,
              isNotOnlyProviderMember: isNotOnlyProviderMember
            });
        break;
    case "Provider" :
        tmp = JsxRuntime.jsx(AdminTopNavbar.make, {
              selectedCategory: "Orders",
              isAdmin: false,
              isConcierge: isConcierge,
              isNotOnlyProviderMember: isNotOnlyProviderMember
            });
        break;
    default:
      tmp = null;
  }
  var x = Belt_Set.size(state.providerFilter);
  var x$1 = Belt_Set.size(state.statusFilter);
  var tmp$1;
  if (state.orders.length !== 0) {
    var match$1 = state.sortBy;
    var match$2 = state.sortBy;
    var match$3 = state.sortBy;
    var match$4 = state.sortBy;
    var match$5 = state.sortBy;
    var match$6 = state.sortBy;
    var tmp$2;
    if (state.totalPages < 2) {
      var match$7 = state.totalReservations;
      tmp$2 = match$7 !== 0 ? (
          match$7 !== 1 ? "Showing all" : "Showing 1"
        ) : "No orders found";
    } else {
      tmp$2 = "Showing " + (String(state.offset + 1 | 0) + (" to " + (String(state.offset + state.orders.length | 0) + (" of " + String(state.totalReservations)))));
    }
    tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsxs("div", {
                  children: [
                    JsxRuntime.jsxs("div", {
                          children: [
                            JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsx(Checkbox.make, {
                                        id: "order--all--selected",
                                        size: "SM",
                                        checked: Belt_Set.size(state.selectedOrders) === orders.length && orders.length !== 0,
                                        onChange: (function (param) {
                                            dispatch("ToggleSelectionOfAllItems");
                                          })
                                      }),
                                  className: css.checkbox
                                }),
                            JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsxs(Control.make, {
                                        className: css.controlRightAlign,
                                        onClick: (function (param) {
                                            dispatch({
                                                  TAG: "SortBy",
                                                  _0: "ID"
                                                });
                                          }),
                                        children: [
                                          "ID",
                                          JsxRuntime.jsx(IconSorting.make, {
                                                size: "XXS",
                                                direction: typeof match$1 === "object" && match$1.NAME === "ID" ? match$1.VAL : undefined,
                                                className: css.iconSort
                                              })
                                        ]
                                      }),
                                  className: Cx.cx([
                                        css.id,
                                        css.tableHeaderCell
                                      ])
                                }),
                            JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsxs(Control.make, {
                                        className: css.controlRightAlign,
                                        onClick: (function (param) {
                                            dispatch({
                                                  TAG: "SortBy",
                                                  _0: "Product"
                                                });
                                          }),
                                        children: [
                                          "Product",
                                          JsxRuntime.jsx(IconSorting.make, {
                                                size: "XXS",
                                                direction: typeof match$2 === "object" && match$2.NAME === "Product" ? match$2.VAL : undefined,
                                                className: css.iconSort
                                              })
                                        ]
                                      }),
                                  className: Cx.cx([
                                        css.product,
                                        css.tableHeaderCell
                                      ])
                                }),
                            JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsxs(Control.make, {
                                        className: css.controlRightAlign,
                                        onClick: (function (param) {
                                            dispatch({
                                                  TAG: "SortBy",
                                                  _0: "Provider"
                                                });
                                          }),
                                        children: [
                                          "Provider",
                                          JsxRuntime.jsx(IconSorting.make, {
                                                size: "XXS",
                                                direction: typeof match$3 === "object" && match$3.NAME === "Provider" ? match$3.VAL : undefined,
                                                className: css.iconSort
                                              })
                                        ]
                                      }),
                                  className: Cx.cx([
                                        css.provider,
                                        css.tableHeaderCell
                                      ])
                                }),
                            JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsxs(Control.make, {
                                        className: css.controlRightAlign,
                                        onClick: (function (param) {
                                            dispatch({
                                                  TAG: "SortBy",
                                                  _0: "User"
                                                });
                                          }),
                                        children: [
                                          "User",
                                          JsxRuntime.jsx(IconSorting.make, {
                                                size: "XXS",
                                                direction: typeof match$4 === "object" && match$4.NAME === "User" ? match$4.VAL : undefined,
                                                className: css.iconSort
                                              })
                                        ]
                                      }),
                                  className: Cx.cx([
                                        css.user,
                                        css.tableHeaderCell
                                      ])
                                }),
                            JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsxs(Control.make, {
                                        className: css.controlRightAlign,
                                        onClick: (function (param) {
                                            dispatch({
                                                  TAG: "SortBy",
                                                  _0: "Company"
                                                });
                                          }),
                                        children: [
                                          "Company",
                                          JsxRuntime.jsx(IconSorting.make, {
                                                size: "XXS",
                                                direction: typeof match$5 === "object" && match$5.NAME === "Company" ? match$5.VAL : undefined,
                                                className: css.iconSort
                                              })
                                        ]
                                      }),
                                  className: Cx.cx([
                                        css.company,
                                        css.tableHeaderCell
                                      ])
                                }),
                            JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsxs(Control.make, {
                                        className: css.controlRightAlign,
                                        onClick: (function (param) {
                                            dispatch({
                                                  TAG: "SortBy",
                                                  _0: "Status"
                                                });
                                          }),
                                        children: [
                                          "Status",
                                          JsxRuntime.jsx(IconSorting.make, {
                                                size: "XXS",
                                                direction: typeof match$6 === "object" && match$6.NAME === "Status" ? match$6.VAL : undefined,
                                                className: css.iconSort
                                              })
                                        ]
                                      }),
                                  className: Cx.cx([
                                        css.status,
                                        css.tableHeaderCell
                                      ])
                                })
                          ],
                          className: css.orderDataviewHeader
                        }),
                    JsxRuntime.jsx("div", {
                          children: Belt_Array.mapWithIndex(state.orders, (function (idx, order) {
                                  var x = order.user.image;
                                  var tmp;
                                  if (Belt_Set.has(state.singleOrderId, order.id)) {
                                    tmp = JsxRuntime.jsx(Dropdown.make, {
                                          className: css.statusDropdown,
                                          children: JsxRuntime.jsx(Dropdown.Trigger.make, {
                                                className: Cx.cx([
                                                      css.dropdown,
                                                      css.changing
                                                    ]),
                                                iconSize: "XXS",
                                                iconColor: "White",
                                                children: "Updating..."
                                              })
                                        });
                                  } else {
                                    var match = order.status;
                                    var tmp$1;
                                    switch (match) {
                                      case "InProgress" :
                                          tmp$1 = css.inProgress;
                                          break;
                                      case "Finalized" :
                                          tmp$1 = css.finalized;
                                          break;
                                      case "Cancelled" :
                                          tmp$1 = css.cancelled;
                                          break;
                                      
                                    }
                                    var match$1 = order.status;
                                    var tmp$2;
                                    switch (match$1) {
                                      case "InProgress" :
                                          tmp$2 = "In Progress";
                                          break;
                                      case "Finalized" :
                                          tmp$2 = "Finalized";
                                          break;
                                      case "Cancelled" :
                                          tmp$2 = "Cancelled";
                                          break;
                                      
                                    }
                                    tmp = JsxRuntime.jsxs(Dropdown.make, {
                                          className: css.statusDropdown,
                                          children: [
                                            JsxRuntime.jsx(Dropdown.Trigger.make, {
                                                  className: Cx.cx([
                                                        css.dropdown,
                                                        tmp$1
                                                      ]),
                                                  iconSize: "XXS",
                                                  iconColor: "White",
                                                  children: tmp$2
                                                }),
                                            JsxRuntime.jsxs(Dropdown.Body.make, {
                                                  position: {
                                                    TAG: "Below",
                                                    _0: "LeftEdge"
                                                  },
                                                  className: css.dropdownBody,
                                                  children: [
                                                    JsxRuntime.jsx(Control.make, {
                                                          className: css.pointer,
                                                          onClick: (function (param) {
                                                              dispatch({
                                                                    TAG: "SingleOrderStatusUpdate",
                                                                    _0: order.id,
                                                                    _1: "InProgress"
                                                                  });
                                                            }),
                                                          children: JsxRuntime.jsx("div", {
                                                                children: JsxRuntime.jsx("label", {
                                                                      children: "In Progress",
                                                                      className: css.label
                                                                    }),
                                                                className: css.dropdownBodyRow
                                                              })
                                                        }),
                                                    JsxRuntime.jsx(Control.make, {
                                                          className: css.pointer,
                                                          onClick: (function (param) {
                                                              dispatch({
                                                                    TAG: "SingleOrderStatusUpdate",
                                                                    _0: order.id,
                                                                    _1: "Finalized"
                                                                  });
                                                            }),
                                                          children: JsxRuntime.jsx("div", {
                                                                children: JsxRuntime.jsx("label", {
                                                                      children: "Finalized",
                                                                      className: css.label
                                                                    }),
                                                                className: css.dropdownBodyRow
                                                              })
                                                        }),
                                                    JsxRuntime.jsx(Control.make, {
                                                          className: css.pointer,
                                                          onClick: (function (param) {
                                                              dispatch({
                                                                    TAG: "SingleOrderStatusUpdate",
                                                                    _0: order.id,
                                                                    _1: "Cancelled"
                                                                  });
                                                            }),
                                                          children: JsxRuntime.jsx("div", {
                                                                children: JsxRuntime.jsx("label", {
                                                                      children: "Cancelled",
                                                                      className: css.label
                                                                    }),
                                                                className: css.dropdownBodyRow
                                                              })
                                                        })
                                                  ]
                                                })
                                          ]
                                        });
                                  }
                                  return JsxRuntime.jsxs("div", {
                                              children: [
                                                JsxRuntime.jsx("div", {
                                                      children: JsxRuntime.jsx(Checkbox.make, {
                                                            id: ID.toString(order.id),
                                                            size: "SM",
                                                            checked: Belt_Set.has(state.selectedOrders, order.id),
                                                            onChange: (function (param) {
                                                                dispatch({
                                                                      TAG: "ToggleSelectionOfItem",
                                                                      _0: order.id
                                                                    });
                                                              })
                                                          }),
                                                      className: css.checkbox
                                                    }),
                                                JsxRuntime.jsxs("div", {
                                                      children: [
                                                        JsxRuntime.jsx("div", {
                                                              children: ID.toString(order.id),
                                                              className: css.title
                                                            }),
                                                        JsxRuntime.jsx("div", {
                                                              children: Format(order.orderedAt, "MM/dd/yyyy hh:mm aaaa"),
                                                              className: css.subTitle
                                                            })
                                                      ],
                                                      className: css.id
                                                    }),
                                                JsxRuntime.jsxs("div", {
                                                      children: [
                                                        JsxRuntime.jsx("div", {
                                                              children: JsxRuntime.jsx(A.make, {
                                                                    href: order.url,
                                                                    targetBlank: true,
                                                                    className: css.title,
                                                                    children: $$String.capitalize_ascii(order.name)
                                                                  }),
                                                              className: css.title
                                                            }),
                                                        JsxRuntime.jsxs("div", {
                                                              children: [
                                                                $$String.capitalize_ascii(order.category),
                                                                JsxRuntime.jsx("span", {
                                                                      className: css.dot
                                                                    }),
                                                                Money.formatWithCents(order.pricePerMonth),
                                                                "/month",
                                                                JsxRuntime.jsx("span", {
                                                                      className: css.dot
                                                                    }),
                                                                "qty " + String(order.quantity)
                                                              ],
                                                              className: css.subTitle
                                                            })
                                                      ],
                                                      className: css.product
                                                    }),
                                                JsxRuntime.jsx("div", {
                                                      children: order.providerName,
                                                      className: css.provider
                                                    }),
                                                JsxRuntime.jsx("div", {
                                                      children: JsxRuntime.jsxs("div", {
                                                            children: [
                                                              JsxRuntime.jsx(Control.AsLink.make, {
                                                                    className: css.link,
                                                                    onClick: (function (param) {
                                                                        
                                                                      }),
                                                                    children: order.user.firstName + (" " + order.user.lastName)
                                                                  }),
                                                              JsxRuntime.jsxs("div", {
                                                                    children: [
                                                                      x !== undefined ? JsxRuntime.jsx("div", {
                                                                              children: JsxRuntime.jsx("img", {
                                                                                    alt: order.user.firstName,
                                                                                    src: x
                                                                                  }),
                                                                              className: css.avatar
                                                                            }) : null,
                                                                      JsxRuntime.jsxs("div", {
                                                                            children: [
                                                                              JsxRuntime.jsx("div", {
                                                                                    children: order.user.firstName + (" " + order.user.lastName),
                                                                                    className: css.name
                                                                                  }),
                                                                              JsxRuntime.jsx("div", {
                                                                                    children: order.user.company,
                                                                                    className: css.designation
                                                                                  }),
                                                                              JsxRuntime.jsx("div", {
                                                                                    children: order.user.phoneNumber,
                                                                                    className: css.phone
                                                                                  }),
                                                                              JsxRuntime.jsx("div", {
                                                                                    children: JsxRuntime.jsx(A.make, {
                                                                                          href: "mailto:info@datacenters.com?subject=Order Enquiry",
                                                                                          className: css.link,
                                                                                          children: order.user.email
                                                                                        }),
                                                                                    className: css.email
                                                                                  })
                                                                            ],
                                                                            className: css.details
                                                                          })
                                                                    ],
                                                                    className: css.cardInfo
                                                                  })
                                                            ],
                                                            className: css.userDropdown
                                                          }),
                                                      className: css.user
                                                    }),
                                                JsxRuntime.jsx("div", {
                                                      children: order.user.company,
                                                      className: css.company
                                                    }),
                                                JsxRuntime.jsx("div", {
                                                      children: tmp,
                                                      className: css.status
                                                    })
                                              ],
                                              className: css.dataRow
                                            }, String(idx));
                                })),
                          className: css.orderDataviewBody
                        })
                  ],
                  className: css.orderDataview
                }),
            JsxRuntime.jsxs("div", {
                  children: [
                    state.totalPages > 1 ? JsxRuntime.jsx(Pagination.make, {
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            onPageClick: (function (pageNum) {
                                dispatch({
                                      TAG: "Paginate",
                                      _0: pageNum
                                    });
                              })
                          }) : null,
                    JsxRuntime.jsx("div", {
                          children: tmp$2,
                          className: css.paginationInfo
                        })
                  ],
                  className: css.paginationBar
                })
          ]
        });
  } else {
    tmp$1 = JsxRuntime.jsxs("div", {
          children: [
            JsxRuntime.jsx("span", {
                  children: JsxRuntime.jsx(IconInfo.make, {
                        size: "MD",
                        color: "DarkOrange"
                      }),
                  className: css.icon
                }),
            "No Orders Found"
          ],
          className: css.noRecordCard
        });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                tmp,
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("h1", {
                              children: "Orders",
                              className: css.titleMain
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(SearchField.make, {
                                            id: "orders-search",
                                            value: state.search,
                                            placeholder: "Search",
                                            inputClassName: css.searchField,
                                            iconClassName: css.searchIcon,
                                            onChange: (function ($$event) {
                                                dispatch({
                                                      TAG: "UpdateSearchInput",
                                                      _0: $$event.target.value
                                                    });
                                              })
                                          }),
                                      JsxRuntime.jsxs(Dropdown.make, {
                                            children: [
                                              JsxRuntime.jsx(Dropdown.Trigger.make, {
                                                    className: css.dropdown,
                                                    children: x !== 0 ? (
                                                        x !== 1 ? String(x) + " providers" : "1 provider"
                                                      ) : "All providers"
                                                  }),
                                              JsxRuntime.jsx(Dropdown.Body.make, {
                                                    position: {
                                                      TAG: "Below",
                                                      _0: "LeftEdge"
                                                    },
                                                    className: css.dropdownBody,
                                                    children: Belt_Array.map(props.providers, (function (provider) {
                                                            var id = "order-providers-filter--provider-" + ID.toString(provider.id);
                                                            return JsxRuntime.jsxs("div", {
                                                                        children: [
                                                                          JsxRuntime.jsx(Checkbox.make, {
                                                                                id: id,
                                                                                size: "SM",
                                                                                checked: Belt_Set.has(state.providerFilter, provider.id),
                                                                                onChange: (function (param) {
                                                                                    dispatch({
                                                                                          TAG: "ToggleProviderFilter",
                                                                                          _0: provider.id
                                                                                        });
                                                                                  })
                                                                              }),
                                                                          JsxRuntime.jsx("label", {
                                                                                children: provider.name,
                                                                                className: css.label,
                                                                                htmlFor: id
                                                                              })
                                                                        ],
                                                                        className: css.dropdownBodyRow
                                                                      }, id);
                                                          }))
                                                  })
                                            ]
                                          }),
                                      JsxRuntime.jsxs(Dropdown.make, {
                                            children: [
                                              JsxRuntime.jsx(Dropdown.Trigger.make, {
                                                    className: css.dropdown,
                                                    children: x$1 !== 0 ? (
                                                        x$1 !== 1 ? String(x$1) + " statuses" : "1 status"
                                                      ) : "All statuses"
                                                  }),
                                              JsxRuntime.jsxs(Dropdown.Body.make, {
                                                    position: {
                                                      TAG: "Below",
                                                      _0: "LeftEdge"
                                                    },
                                                    className: css.dropdownBody,
                                                    children: [
                                                      JsxRuntime.jsxs("div", {
                                                            children: [
                                                              JsxRuntime.jsx(Checkbox.make, {
                                                                    id: "order-status-filter--in_progress",
                                                                    size: "SM",
                                                                    checked: Belt_Set.has(state.statusFilter, "InProgress"),
                                                                    onChange: (function (param) {
                                                                        dispatch({
                                                                              TAG: "ToggleStatusFilter",
                                                                              _0: "InProgress"
                                                                            });
                                                                      })
                                                                  }),
                                                              JsxRuntime.jsx("label", {
                                                                    children: "In Progress",
                                                                    className: css.label,
                                                                    htmlFor: "order-status-filter--in_progress"
                                                                  })
                                                            ],
                                                            className: css.dropdownBodyRow
                                                          }),
                                                      JsxRuntime.jsxs("div", {
                                                            children: [
                                                              JsxRuntime.jsx(Checkbox.make, {
                                                                    id: "order-status-filter--finalized",
                                                                    size: "SM",
                                                                    checked: Belt_Set.has(state.statusFilter, "Finalized"),
                                                                    onChange: (function (param) {
                                                                        dispatch({
                                                                              TAG: "ToggleStatusFilter",
                                                                              _0: "Finalized"
                                                                            });
                                                                      })
                                                                  }),
                                                              JsxRuntime.jsx("label", {
                                                                    children: "Finalized",
                                                                    className: css.label,
                                                                    htmlFor: "order-status-filter--finalized"
                                                                  })
                                                            ],
                                                            className: css.dropdownBodyRow
                                                          }),
                                                      JsxRuntime.jsxs("div", {
                                                            children: [
                                                              JsxRuntime.jsx(Checkbox.make, {
                                                                    id: "order-status-filter--cancelled",
                                                                    size: "SM",
                                                                    checked: Belt_Set.has(state.statusFilter, "Cancelled"),
                                                                    onChange: (function (param) {
                                                                        dispatch({
                                                                              TAG: "ToggleStatusFilter",
                                                                              _0: "Cancelled"
                                                                            });
                                                                      })
                                                                  }),
                                                              JsxRuntime.jsx("label", {
                                                                    children: "Cancelled",
                                                                    className: css.label,
                                                                    htmlFor: "order-status-filter--cancelled"
                                                                  })
                                                            ],
                                                            className: css.dropdownBodyRow
                                                          })
                                                    ]
                                                  })
                                            ]
                                          }),
                                      state.orders.length !== 0 ? JsxRuntime.jsx(Button.AsLink.make, {
                                              href: "/dashboard/orders/export.csv?query=" + (state.search + (Belt_List.reduce(Belt_Set.toList(state.providerFilter), "", (function (acc, providerId) {
                                                          return acc + ("&provider_ids" + (encodeURIComponent("[]") + ("=" + ID.toString(providerId))));
                                                        })) + Belt_List.reduce(Belt_Set.toList(state.statusFilter), "", (function (acc, status) {
                                                          return acc + ("&status" + (encodeURIComponent("[]") + ("=" + OrderStatus.toString(status))));
                                                        })))),
                                              size: "SM",
                                              color: "Teal",
                                              className: css.exportToCsvButton,
                                              children: "Export to CSV"
                                            }) : JsxRuntime.jsx(Button.make, {
                                              size: "SM",
                                              color: "Teal",
                                              visuallyDisabled: true,
                                              className: css.exportToCsvButton,
                                              onClick: (function (prim) {
                                                  
                                                }),
                                              children: "Export to CSV"
                                            })
                                    ],
                                    className: css.headerControls
                                  }),
                              className: css.filters
                            }),
                        tmp$1
                      ],
                      className: css.contentWrapper
                    }),
                JsxRuntime.jsx(AdminOrdersActionBar.make, {
                      selection: Belt_Set.size(state.selectedOrders),
                      error: state.serverError,
                      busy: state.fetching,
                      bulkSetInProgress: (function (param) {
                          dispatch({
                                TAG: "PerformBulkAction",
                                _0: "InProgress"
                              });
                        }),
                      bulkSetFinalized: (function (param) {
                          dispatch({
                                TAG: "PerformBulkAction",
                                _0: "Finalized"
                              });
                        }),
                      bulkSetCancelled: (function (param) {
                          dispatch({
                                TAG: "PerformBulkAction",
                                _0: "Cancelled"
                              });
                        }),
                      deselect: (function () {
                          dispatch("DeselectAll");
                        })
                    }),
                state.showNotificationMessage ? JsxRuntime.jsx(AdminOrdersNotificationBar.make, {}) : null
              ],
              className: css.container
            });
}

var AdminOrders = {
  css: css,
  OrderStatusId: OrderStatusId,
  make: AdminOrders$AdminOrders
};

function AdminOrders$default(props) {
  var props$1 = props.props;
  return JsxRuntime.jsx(AdminOrders$AdminOrders, {
              currentPage: props$1.currentPage,
              offset: props$1.offset,
              perPage: props$1.perPage,
              totalPages: props$1.totalPages,
              totalReservations: props$1.totalReservations,
              orders: Belt_Array.map(props$1.orders, AdminOrderData.fromJs),
              providers: Belt_Array.map(props$1.providers, AdminOrderProvider.fromJs),
              isConcierge: props$1.isConcierge,
              isNotOnlyProviderMember: props$1.isNotOnlyProviderMember,
              userRole: Role.fromString(props.context.userRole)
            });
}

var $$default = AdminOrders$default;

export {
  AdminOrders ,
  $$default as default,
}
/* css Not a pure module */
