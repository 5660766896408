// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Container from "../../../styleguide/components/Container/Container.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as AdminOrdersScss from "./AdminOrders.scss";

var css = AdminOrdersScss;

function AdminOrdersNotificationBar(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsx(Container.make, {
                          className: css.notificationBarInner,
                          children: JsxRuntime.jsx("div", {
                                children: "Your order is hidden (not lost) by the current filters. Update the status filters to see it again.",
                                className: css.notificationBarError
                              })
                        }),
                    className: css.notificationBar
                  }),
              className: css.notificationBarContainer
            });
}

var make = AdminOrdersNotificationBar;

export {
  css ,
  make ,
}
/* css Not a pure module */
