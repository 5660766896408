// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Control from "../../../styleguide/components/Control/Control.res.js";
import * as Spinner from "../../../styleguide/components/Spinner/Spinner.res.js";
import * as Dropdown from "../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as Container from "../../../styleguide/components/Container/Container.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as AdminOrdersScss from "./AdminOrders.scss";

var css = AdminOrdersScss;

function AdminOrdersActionBar(props) {
  var deselect = props.deselect;
  var error = props.error;
  var selection = props.selection;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs(Container.make, {
                    className: css.actionBarInner,
                    children: [
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("span", {
                                    children: selection !== 1 ? String(selection) + " orders selected" : "1 order selected"
                                  }),
                              JsxRuntime.jsx(Control.AsLink.make, {
                                    color: "White",
                                    className: css.linkCancel,
                                    onClick: (function (param) {
                                        deselect();
                                      }),
                                    children: "Cancel"
                                  })
                            ],
                            className: css.actionBarInfo
                          }),
                      JsxRuntime.jsx("div", {
                            children: error !== undefined ? error : null,
                            className: css.actionBarError
                          }),
                      props.busy ? JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Spinner.make, {
                                    size: "LG",
                                    color: "White"
                                  }),
                              className: css.actionBarSpinner
                            }) : JsxRuntime.jsxs(Dropdown.make, {
                              children: [
                                JsxRuntime.jsx(Dropdown.Trigger.make, {
                                      className: css.actionBarDropdown,
                                      iconSize: "XXS",
                                      iconColor: "Gray",
                                      children: "Bulk action"
                                    }),
                                JsxRuntime.jsxs(Dropdown.Body.make, {
                                      position: {
                                        TAG: "Above",
                                        _0: "RightEdge"
                                      },
                                      className: css.dropdownBody,
                                      children: [
                                        JsxRuntime.jsx(Control.make, {
                                              className: css.dropdownBodyRow,
                                              onClick: props.bulkSetInProgress,
                                              children: "In Progress"
                                            }),
                                        JsxRuntime.jsx(Control.make, {
                                              className: css.dropdownBodyRow,
                                              onClick: props.bulkSetFinalized,
                                              children: "Finalized"
                                            }),
                                        JsxRuntime.jsx(Control.make, {
                                              className: css.dropdownBodyRow,
                                              onClick: props.bulkSetCancelled,
                                              children: "Cancelled"
                                            })
                                      ]
                                    })
                              ]
                            })
                    ]
                  }),
              className: Cx.cx([
                    css.actionBar,
                    selection !== 0 ? css.shown : css.hidden
                  ])
            });
}

var make = AdminOrdersActionBar;

export {
  css ,
  make ,
}
/* css Not a pure module */
